import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./App.css";
import FormInput from "./component/FormInput/FormInput";
import DisplayImage from "./component/DisplayImage/DisplayImage2";
import { Button, Form } from "react-bootstrap";
import { toJpeg } from "html-to-image";
import { useState, useRef, useEffect, useMemo } from "react";

const Homepage = () => {
    const [numberCooponMin, setNumberCooponMin] = useState("000000");
    const [numberCooponMax, setNumberCooponMax] = useState("000000");
    const [noMinCooponInput, setNoMinCooponInput] = useState("000");
    const [noMaxCooponInput, setNoMaxCooponInput] = useState("");
    const [dateCooponInput, setDateCooponInput] = useState();
    const [colorFont, setColorFont] = useState("#1c1d1c");
    const [colorNumber, setColorNumber] = useState("#1c1d1c");
    const [sizeImgMin, setSizeImgMin] = useState();
    const [sizeImgMax, setSizeImgMax] = useState();
    const [selectedFile, setSelectedFile] = useState();
    const [preview, setPreview] = useState();
    const [arrNum, setArrNum] = useState([]);
    const [arrNoMaxCooponInput, setArrNoMaxCooponInput] = useState([]);

    const handleCreateImage = () => {
        htmlToImageConvert();
    };

    const dateTH = useMemo(() => {
        if (!dateCooponInput) {
            const date = new Date();

            const result = date.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
            });

            return result;
        }

        const date = new Date(dateCooponInput);

        const result = date.toLocaleDateString("th-TH", {
            year: "numeric",
            month: "long",
            day: "numeric",
        });

        return result;
    }, [dateCooponInput]);

    const yearThai = useMemo(() => {
        if (!dateCooponInput) {
            const date = new Date();

            const result = date.toLocaleDateString("th-TH", {
                year: "numeric",
                month: "long",
                day: "numeric",
            });

            return result;
        }

        const date = new Date(dateCooponInput);
        const result = date.toLocaleDateString("th-TH", {
            year: "numeric",
        });

        return result.toString().substr(-2);
    }, [dateCooponInput]);

    const dateEN = useMemo(() => {
        if (!dateCooponInput) {
            const date = new Date();

            const result = date.toLocaleDateString("en-GB", {
                year: "numeric",
                month: "long",
                day: "numeric",
            });

            return result;
        }
        const date = new Date(dateCooponInput);

        const result = date.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "long",
            day: "numeric",
        });

        return result;
    }, [dateCooponInput]);

    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined);
            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile);
        setPreview(objectUrl);

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile]);

    const onSelectFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
            return;
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedFile(e.target.files[0]);
    };

    const elementRef = useRef(null);
    const elementRef2 = useRef([]);

    const handleSetNoMaxCooponInput = (numm) => {
        setNoMaxCooponInput(numm);
    };

    const convertNumberTxt = (str) => {
        //console.log(typeof str);
        const listNumber = [
            "ZER",
            "ONE",
            "TWO",
            "THR",
            "FOR",
            "FIV",
            "SIX",
            "SEV",
            "EGT",
            "NIN",
        ];
        const splitStr = str.split("");
        const numberToText = splitStr.map(
            (txtNumber) => listNumber[Number(txtNumber)]
        );
        return (
            <div className="textNumberloop">
                {numberToText.map((m, i) => (
                    <div className="txtNumCoopon">
                        <div className="txtNum1"  style={{color:colorNumber,fontFamily:'LOT2'}}> {splitStr[i]}</div>
                        <div className="txtNum2" style={{color:colorFont,fontFamily:'LOT2'}}>{m}</div>
                    </div>
                ))}
            </div>
        );
    };

    const renderList = (numberCooponMax, dateEN, dateTH) => {
        let listItems = [];

        for (let r = numberCooponMin; r <= numberCooponMax; r++) {
            listItems.push(
                <Col
                    md="6"
                    style={{
                        width: `100%`,
                        height: "670px",
                    }}
                >
                    <Card
                        ref={(el) => (elementRef2.current[r] = el)}
                        className="cardCoopong"
                        style={{
                            color: "#ffff",
                            paddingRight: "130px",
                            width: `100%`,
                            height: "100%",
                            backgroundSize: "cover",
                            backgroundImage: `url(${preview})`,
                        }}
                    >
                        <Card.Body>
                            <Row className="mb-4 ">
                                <Col md="12">
                                    <div className="textBoxStyles2">
                                        {convertNumberTxt(r.toString())}
                                    </div>
                                </Col>
                            </Row>

                            {/*<Row>*/}
                            {/*  <Col md="12">*/}
                            {/*    <div className="dateTitle">*/}
                            {/*      <div className="dateTitleThai">{dateTH}</div>*/}
                            {/*      <div className="dateTitleEng">{dateEN}</div>*/}
                            {/*    </div>*/}
                            {/*  </Col>*/}
                            {/*</Row>*/}

                            {/*<Row>*/}
                            {/*  <Col md="12">*/}
                            {/*    <div className="noCooponTitle">{noMaxCooponInput}</div>*/}
                            {/*  </Col>*/}
                            {/*</Row>*/}
                        </Card.Body>
                    </Card>
                </Col>
            );
        }

        return listItems;
    };

    const htmlToImageConvert = async () => {
        for (let i = numberCooponMin; i <= numberCooponMax; i++) {
            //    const fontEmbedCss = await htmlToImage.getFontEmbedCSS(elementRef2.current[i]);
            toJpeg(elementRef2.current[i], { cacheBust: false, quality: 0.9 })
                .then((dataUrl) => {
                    const link = document.createElement("a");
                    link.download = `${yearThai}-${sizeImgMin}-${noMaxCooponInput}-${i}.png`;
                    link.href = dataUrl;
                    link.click();
                })
                .catch((err) => {
                    console.log("why not");
                });
        }
    };

    return (
        <div className="App">
            <Container>
                <Card className="displayCard">
                    <Row className="mb-4">
                        <Col md="5" className="textLeft displayInput">
                            <FormInput
                                setNumberCooponMin={setNumberCooponMin}
                                setNumberCooponMax={setNumberCooponMax}
                                setNoMinCooponInput={setNoMinCooponInput}
                                setNoMaxCooponInput={handleSetNoMaxCooponInput}
                                setDateCooponInput={setDateCooponInput}
                                setSizeImgMin={setSizeImgMin}
                                setSizeImgMax={setSizeImgMax}
                                setColorFont={setColorFont}
                                setColorNumber={setColorNumber}
                                onSelectFile={onSelectFile}
                                arrNum={arrNum}
                                setArrNum={setArrNum}
                            />
                        </Col>

                        <Col md="7" className="displayImg">
                            <DisplayImage
                                sizeImgMin={sizeImgMin}
                                sizeImgMax={sizeImgMax}
                                selectedFile={selectedFile}
                                preview={preview}
                                elementRef={elementRef}
                                numberCooponInput={numberCooponMax}
                                noCooponInput={noMaxCooponInput}
                                dateCooponInput={dateCooponInput}
                                colorFont={colorFont}
                                colorNumber={colorNumber}
                                arrNum={arrNum}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col md="12" className="textRight">
                            <Button variant="secondary" disabled>
                                โฟลเดอร์
                            </Button>{" "}
                            <Button variant="success" onClick={handleCreateImage}>
                                สร้าง
                            </Button>{" "}
                        </Col>
                    </Row>
                </Card>{" "}
                <Row className="allCoopong">
                    {renderList(numberCooponMax, dateEN, dateTH)}
                </Row>
            </Container>
        </div>
    );
};

export default Homepage;
