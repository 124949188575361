import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./App.css";
import FormInput from "./component/FormInput/FormInput";
import DisplayImage from "./component/DisplayImage/DisplayImage";
import { Button, Form } from "react-bootstrap";
import { toJpeg, toPng } from "html-to-image";
import { useState, useRef, useEffect, useMemo } from "react";
// import { nodeHtmlToImage } from "node-html-to-image";

const Homepage = () => {
  const [numberCooponMin, setNumberCooponMin] = useState("000000");
  const [numberCooponMax, setNumberCooponMax] = useState("000000");
  const [noMinCooponInput, setNoMinCooponInput] = useState("000");
  const [noMaxCooponInput, setNoMaxCooponInput] = useState("");
  const [dateCooponInput, setDateCooponInput] = useState();
  const [colorFont, setColorFont] = useState("#1c1d1c");
  const [colorNumber, setColorNumber] = useState("#1c1d1c");
  const [sizeImgMin, setSizeImgMin] = useState();
  const [sizeImgMax, setSizeImgMax] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const [arrNum, setArrNum] = useState([]);
  const [arrNoMaxCooponInput, setArrNoMaxCooponInput] = useState([]);
  const [fontRender, setFontRender] = useState("Jibjibfont");

  const [rendennumberCooponMax, setRendennumberCooponMax] = useState();

  const handleCreateImage = () => {
    setRendennumberCooponMax(numberCooponMax);
  };

  const handleSaveImageAll = async () => {
    await htmlToImageConvert();
  };

  const handleSaveImage = (r) => {
    toPng(elementRef2.current[r])
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `${yearThai}-${noMinCooponInput}-${noMaxCooponInput}-${r
          .toString()
          .padStart(6, "0")}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log("why not");
      });
  };

  const htmlToImageConvert = async () => {
    for (let i = numberCooponMin; i <= rendennumberCooponMax; i++) {
      await toPng(elementRef2.current[i])
        .then(async (dataUrl) => {
          const link = document.createElement("a");
          link.download = `${yearThai}-${noMinCooponInput}-${noMaxCooponInput}-${i
            .toString()
            .padStart(6, "0")}.png`;
          link.href = dataUrl;
          await link.click();
        })
        .catch((err) => {
          console.log("why not");
        });
    }
  };

  const dateTH = useMemo(() => {
    if (!dateCooponInput) {
      const date = new Date();

      const result = date.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      return result;
    }

    const date = new Date(dateCooponInput);

    const result = date.toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return result;
  }, [dateCooponInput]);

  const yearThai = useMemo(() => {
    if (!dateCooponInput) {
      const date = new Date();

      const result = date.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      return result;
    }

    const date = new Date(dateCooponInput);
    const result = date.toLocaleDateString("th-TH", {
      year: "numeric",
    });

    return result.toString().substr(-2);
  }, [dateCooponInput]);

  const dateEN = useMemo(() => {
    if (!dateCooponInput) {
      const date = new Date();

      const result = date.toLocaleDateString("en-GB", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      return result;
    }
    const date = new Date(dateCooponInput);

    const result = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return result;
  }, [dateCooponInput]);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };

  const elementRef = useRef(null);
  const elementRef2 = useRef([]);

  const handleSetNoMaxCooponInput = (numm) => {
    setNoMaxCooponInput(numm);
  };

  const convertNumberTxt = (str) => {
    //console.log(typeof str);
    const listNumber = [
      "ZER",
      "ONE",
      "TWO",
      "THR",
      "FOR",
      "FIV",
      "SIX",
      "SEV",
      "EGT",
      "NIN",
    ];

    const text = str.padStart(6, "0");
    const splitStr = text.split("");
    const numberToText = splitStr.map(
      (txtNumber) => listNumber[Number(txtNumber)]
    );

    return (
      <div className="textNumberloop">
        {numberToText.map((m, i) => (
          <div className="txtNumCoopon">
            <div className="txtNum1" style={{ color: colorNumber }}>
              {" "}
              {splitStr[i]}
            </div>
            <div className="txtNum2" style={{ color: colorFont }}>
              {m}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderList = (rendennumberCooponMax, dateEN, dateTH) => {
    let listItems = [];

    if (rendennumberCooponMax) {
      for (let r = numberCooponMin; r <= rendennumberCooponMax; r++) {
        listItems.push(
          <Col md="4">
            <Row>
              <Col md="7"></Col>
              <Col
                md="2"
                style={{
                  marginBottom: "5px",
                }}
              >
                {" "}
                <Button
                  variant="primary"
                  onClick={() => {
                    handleSaveImage(r);
                  }}
                >
                  ดาวน์โหลด
                </Button>
                <br />
              </Col>
              <Col md="1"></Col>
            </Row>
            <Row>
              <Col
                style={{
                  marginBottom: "5px",
                }}
              >
                <div
                  ref={(el) => (elementRef2.current[r] = el)}
                  className="cardCoopong"
                  style={{
                    fontFamily: `${fontRender}`,
                    color: "#ffff",

                    backgroundRepeat: " no-repeat",
                    backgroundSize: "cover",
                    backgroundImage: `url(${preview})`,
                  }}
                >
                  <Row>
                    <Col md="5">&nbsp;</Col>
                    <Col md="7">{convertNumberTxt(r.toString())}</Col>
                  </Row>
                  <Row>
                    <Col>&nbsp;</Col>
                  </Row>
                  <Row>
                    <Col>&nbsp;</Col>
                  </Row>
                  <Row>
                    <Col>&nbsp;</Col>
                  </Row>
                  <Row>
                    <Col>&nbsp;</Col>
                  </Row>
                  <Row>
                    <Col>&nbsp;</Col>
                  </Row>
                  <Row>
                    <Col>&nbsp;</Col>
                  </Row>
                  <Row>
                    <Col>&nbsp;</Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        );
      }

      return listItems;
    }
  };

  return (
    <div className="App">
      <Container>
        <Card className="displayCard">
          <Row className="mb-4">
            <Col md="5" className="textLeft displayInput">
              <FormInput
                setFontRender={setFontRender}
                setRendennumberCooponMax={setRendennumberCooponMax}
                setNumberCooponMin={setNumberCooponMin}
                setNumberCooponMax={setNumberCooponMax}
                setNoMinCooponInput={setNoMinCooponInput}
                setNoMaxCooponInput={handleSetNoMaxCooponInput}
                setDateCooponInput={setDateCooponInput}
                setSizeImgMin={setSizeImgMin}
                setSizeImgMax={setSizeImgMax}
                setColorFont={setColorFont}
                setColorNumber={setColorNumber}
                onSelectFile={onSelectFile}
                arrNum={arrNum}
                setArrNum={setArrNum}
              />
            </Col>

            <Col
              md="7"
              className="displayImg"
              style={{ fontFamily: `${fontRender}` }}
            >
              <DisplayImage
                fontRender={fontRender}
                sizeImgMin={sizeImgMin}
                sizeImgMax={sizeImgMax}
                selectedFile={selectedFile}
                preview={preview}
                elementRef={elementRef}
                numberCooponInput={numberCooponMax}
                noCooponInput={noMaxCooponInput}
                dateCooponInput={dateCooponInput}
                colorFont={colorFont}
                colorNumber={colorNumber}
                arrNum={arrNum}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md="12" className="textRight">
              <Button variant="success" onClick={handleCreateImage}>
                สร้าง
              </Button>{" "}
              <Button variant="warning" onClick={handleSaveImageAll}>
                ดาวน์โหลดทั้งหมด
              </Button>{" "}
              {/* <Button variant="secondary" onClick={handleSaveImageAll}>
                โฟลเดอร์
              </Button>{" "} */}
            </Col>
          </Row>
        </Card>{" "}
        <br />
      </Container>

      <Row className="allCoopong">
        {renderList(rendennumberCooponMax, dateEN, dateTH)}
      </Row>
    </div>
  );
};

export default Homepage;
