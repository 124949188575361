import "./DisplayImage.css";
import { useMemo } from "react";
import { Col, Row } from "react-bootstrap";

const DisplayImage = ({
  fontRender,
  elementRef,
  numberCooponInput,
  dateCooponInput,
  noCooponInput,
  selectedFile,
  sizeImgMin,
  sizeImgMax,
  preview,
  arrNum,
  colorFont,
  colorNumber,
}) => {
  const dateTH = useMemo(() => {
    if (!dateCooponInput) {
      const date = new Date();

      const result = date.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      return result;
    }

    const date = new Date(dateCooponInput);

    const result = date.toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return result;
  }, [dateCooponInput]);

  const dateEN = useMemo(() => {
    if (!dateCooponInput) {
      const date = new Date();

      const result = date.toLocaleDateString("en-GB", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      return result;
    }
    const date = new Date(dateCooponInput);

    const result = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return result;
  }, [dateCooponInput]);

  const convertNumberTxt = (str) => {
    //console.log(typeof str);
    const listNumber = [
      "ZER",
      "ONE",
      "TWO",
      "THR",
      "FOR",
      "FIV",
      "SIX",
      "SEV",
      "EGT",
      "NIN",
    ];
    const splitStr = str.split("");
    const numberToText = splitStr.map(
      (txtNumber) => listNumber[Number(txtNumber)]
    );
    return (
      <div className="disPlaytextNumberloop">
        {numberToText.map((m, i) => (
          <div className="disPlaytxtNumCoopon">
            <h3
              className="disPlaytxtNum1"
              style={{ color: colorNumber, fontFamily: `${fontRender}` }}
            >
              {splitStr[i]}
            </h3>
            <div
              className="disPlaytxtNum2"
              style={{ color: colorFont, fontFamily: `${fontRender}` }}
            >
              {m}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <div
        className="cardImage"
        style={{
          backgroundImage: `url(${preview})`,
        }}
        ref={elementRef}
      >
        <div className="cardContent">
          <Row className="mb-4">
            <Col>&nbsp;</Col>
            <Col>
              {" "}
              <div style={{ paddingTop: "16px" }}>
                {convertNumberTxt(numberCooponInput)}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>&nbsp;</Col>
          </Row>
          <Row>
            <Col>&nbsp;</Col>
          </Row>{" "}
          <Row>
            <Col>&nbsp;</Col>
          </Row>{" "}
          <Row>
            <Col>&nbsp;</Col>
          </Row>{" "}
          <Row>
            <Col>&nbsp;</Col>
          </Row>
          <Row>
            <Col>&nbsp;</Col>
          </Row>
          <Row>
            <Col>&nbsp;</Col>
          </Row>
          <Row>
            <Col>&nbsp;</Col>
          </Row>{" "}
          <Row>
            <Col>&nbsp;</Col>
          </Row>{" "}
          <Row>
            <Col>&nbsp;</Col>
          </Row>
          {/* {selectedFile && <img src={preview} />} */}
          {/* <div
            className="contentNumberCoopon "
            style={{ marginLeft: "15px", fontFamily: `${fontRender}` }}
          >
            <div>{convertNumberTxt(numberCooponInput)}</div>
          </div> */}
          {/*<div className="contentDateTHCoopon">*/}
          {/*  <div>{dateTH}</div>*/}
          {/*</div>*/}
          {/*<div className="contentDateENCoopon">*/}
          {/*  <div className="textBoxStyles textBoxDateENCoopon">{dateEN}</div>*/}
          {/*</div>*/}
          {/*<div className="contentNoCoopon">*/}
          {/*  <div className="textBoxStyles textBoxNoCoopon">{noCooponInput}</div>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

export default DisplayImage;
