
const json = {
   user : [
     // {
     //   'username' : 'adminsalak',
     //   'password' : 'oqOdlSYrvAL7NsP'
     // },
     // {
     //   'username' : 'admin@salakthailottery',
     //   'password' : 'JYy@bhHyFJYtC%3x'
     // },
     {
       'username' : 'testapp',
       'password' : 'BjaABy84sGU5'
     },
     {
       'username' : 'admin',
       'password' : 'admin123'
     },
  ]

}
// @ts-ignore
export default json;

